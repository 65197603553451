var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "SalaryPlan" },
    [
      _c(
        "a-button",
        { attrs: { prefix: "piliangdaoru" }, on: { click: _vm.showDrawer } },
        [_vm._v("新增分类")]
      ),
      _c("SalaryTable", { ref: "salaryTable" }),
      _c("NewCategory", {
        ref: "newCategory",
        on: { refresh: _vm.refreshSalary },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }